import BlockContent, {
  BlockContentProps,
} from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import ImageWithCaption from "../components/blocks/ImageWithCaption";
import DownloadButtons from "../components/DownloadButtons";
import Jobs from "../components/JobsSection";
import SignupForm from "../components/SignupForm";
import SocialMediaTeaser from "../components/SocialMediaTeaser";
import TeamSection from "../components/TeamSection";
import { useGlobalState } from "../state/globalState";

export interface MissionProps {
  data: {
    site: {
      siteMetadata: {
        downloadActive: boolean;
      };
    };
    mission: {
      overline: string;
      title: string;
      _rawBody: any;
    };
  };
}

const Mission: React.FC<MissionProps> = ({
  data: {
    mission,
    site: {
      siteMetadata: { downloadActive },
    },
  },
}) => {
  const { t } = useTranslation(["sign_up_form", "common", "mission"]);

  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  const [isDownloadPopupOpen, setIsDownloadPopupOpen] = useGlobalState(
    "isDownloadPopupOpen"
  );

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "light",
      hidden: false,
    });
  }, []);

  const serializers = React.useMemo<BlockContentProps["serializers"]>(
    () => ({
      types: {
        imageWithCaption: ({ node }) => {
          return <ImageWithCaption {...node} />;
        },
      },
    }),
    []
  );

  return (
    <div>
      <section className="flex flex-col justify-center pt-32 mx-auto container-4xl">
        <div className="z-10">
          {/* <h6 className="mb-2 text-xs tracking-widest text-center text-white uppercase opacity-75 font-headline">
              {mission.overline}
            </h6> */}
          <h1 className="text-5xl md:text-6xl lg:text-6.5xl leading-none text-left text-gray-500 font-black uppercase">
            <Trans i18nKey="mission:title">
              Wir machen{" "}
              <span className="inline-block max-w-full font-black text-purple-500 break-words with-hyphens">
                Nachhaltigkeit
              </span>
              <span className="font-black">&nbsp;</span>
              <span className="inline-block max-w-full font-black break-words text-amber-500 with-hyphens">
                normal.
              </span>
            </Trans>
          </h1>
        </div>
      </section>

      <div className="px-4 pb-16 container-4xl">
        <div className="prose-lg text-gray-500">
          <BlockContent blocks={mission._rawBody} serializers={serializers} />
        </div>

        <hr className="my-12" />

        <div className="flex flex-col space-y-4">
          {downloadActive ? (
            <>
              <h2 className="text-center font-headline">
                {t("common:download.call_to_action")}
              </h2>
              <div className="flex flex-row justify-center space-x-4">
                <DownloadButtons
                  buttonClassName="h-10"
                  onClick={() => {
                    setIsDownloadPopupOpen(true);
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <h2 className="text-center font-headline">
                {t("sign_up_form:title")}
              </h2>
              <div className="flex flex-row justify-center">
                <SignupForm />
              </div>
              <span className="px-4 text-xs tracking-wide text-center sm:px-0 font-headline">
                {t("common:privacy.description")}
                &nbsp;
                <Link to="/datenschutzerklaerung" className="text-indigo-400">
                  {t("common:privacy.privacy_policy")}
                </Link>
              </span>
            </>
          )}
        </div>
      </div>

      <div className="py-12 bg-gray-100">
        <SocialMediaTeaser />
      </div>

      <div className="py-12 container-4xl">
        <div className="absolute -mt-32" id="team" />
        <TeamSection />
        <hr className="my-12" />
        <div className="absolute -mt-32" id="jobs" />
        <Jobs />
      </div>
    </div>
  );
};

export default Mission;

export const query = graphql`
  query MissionQuery($language: String!) {
    site {
      siteMetadata {
        downloadActive
      }
    }
    mission: sanitySimplePage(
      slug: { current: { eq: "mission" } }
      i18n_lang: { eq: $language }
    ) {
      overline
      title
      _rawBody
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
