import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

const query = graphql`
  query JobQuery {
    site {
      siteMetadata {
        mailContact
      }
    }
  }
`;

export interface JobProps {
  role: string;
  location: string;
  index: number;
}

const Job: React.FC<JobProps> = ({ role, location, index }) => {
  const { t } = useTranslation(["jobs"]);
  const {
    site: {
      siteMetadata: { mailContact },
    },
  } = useStaticQuery(query);

  return (
    <div className="flex flex-col items-center justify-between px-4 py-6 space-y-4 bg-gray-100 rounded-xl">
      <div className="flex flex-col items-center flex-grow w-full space-y-1">
        <span className="flex-grow text-lg font-bold text-center font-headline text-primary ">
          {role}
        </span>
        <span className="text-xs font-bold tracking-widest text-center text-gray-500 uppercase font-headline">
          {location}
        </span>
      </div>
      <a
        href={`mailto:${mailContact}?subject=Application as ${role}`}
        className="button-blue-filled"
      >
        {t("jobs:apply")}
      </a>
    </div>
  );
};
export default Job;
