import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import Job from "./Job";

const query = graphql`
  query JobSectionQuery {
    allSanityJob(sort: { fields: order }) {
      edges {
        node {
          id
          role
          location
        }
      }
    }
  }
`;

export interface JobsSectionQueryData {
  allSanityJob: {
    edges: {
      node: {
        id: string;
        role: string;
        location: string;
      };
    }[];
  };
}

const JobsSection = () => {
  const { t } = useTranslation(["jobs"]);
  const { allSanityJob }: JobsSectionQueryData = useStaticQuery(query);

  return (
    <div className="flex flex-col space-y-12">
      <div className="flex flex-col space-y-4">
        <h2 className="text-2xl font-headline text-primary">
          {t("jobs:title")}
        </h2>
        <span className="text-gray-500">{t("jobs:description")}</span>
      </div>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
        {allSanityJob.edges.map(({ node: { id, role, location } }, index) => (
          <Job key={id} index={index} role={role} location={location} />
        ))}
      </div>
    </div>
  );
};

export default JobsSection;
