import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

export interface TeamMemberProps {
  name: string;
  role: string;
  image: any;
  index: number;
}

const TeamMember: React.FC<TeamMemberProps> = ({ name, role, image }) => {
  return (
    <div className="flex flex-col items-center space-y-3">
      <div className="w-32 h-32 overflow-hidden rounded-full">
        {image && (
          <GatsbyImage
            className="w-full h-full"
            image={image}
            alt={name}
            imgClassName="rounded-full"
          />
        )}
      </div>
      <div className="flex flex-col space-y-2">
        <span className="text-sm font-bold text-center font-headline">
          {name}
        </span>
        <span className="text-xs font-bold tracking-widest text-center text-gray-500 uppercase font-headline">
          {role}
        </span>
      </div>
    </div>
  );
};

export default TeamMember;
