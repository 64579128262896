import { graphql, useStaticQuery } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import TeamMember from "./TeamMember";

const query = graphql`
  query TeamSectionQuery {
    allSanityTeamMember(sort: { order: ASC, fields: order }) {
      edges {
        node {
          id
          name
          role
          image {
            asset {
              # gatsbyImageData(width: 200, placeholder: BLURRED)
              localFile(width: 256) {
                childImageSharp {
                  gatsbyImageData(width: 128, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface TeamMemberDataEdge {
  node: {
    id: string;
    name: string;
    shortname: string;
    role: string;
    image: {
      asset: {
        gatsbyImageData: IGatsbyImageData;
        localFile: {
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData;
          };
        };
      };
    };
  };
}

export default function TeamSection() {
  const { t } = useTranslation(["team"]);
  const data = useStaticQuery(query);

  return (
    <div className="flex flex-col space-y-12">
      <div className="space-y-4">
        <h2 className="text-2xl font-headline text-primary">
          {t("team:title")}
        </h2>
        <p className="text-gray-500">{t("team:description1")}</p>
      </div>
      <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
        {data.allSanityTeamMember.edges.map(
          ({ node: { id, image, name, role } }: TeamMemberDataEdge) => (
            <TeamMember
              index={0}
              key={id}
              // shortname={node.shortname}
              // image={image.asset.gatsbyImageData}
              image={image.asset.localFile.childImageSharp.gatsbyImageData}
              name={name}
              role={role}
            />
          )
        )}
      </div>
    </div>
  );
}
